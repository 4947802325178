import React from "react";
import classNames from "classnames";
import Timeline from "./Timeline";
import TimelineItem from "./TimelineItem";
import SectionHeader from "./SectionHeader";
import { SectionProps } from "../utils/SectionProps";
import Bounce from "react-activity/lib/Bounce";
import Image from "./Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};



class Roadmap extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const tilesClasses = classNames("tiles-wrap", props.pushLeft && "push-left");

    const outerClasses = classNames(
        "roadmap section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "roadmap-inner section-inner"
    );

    function Education() {
      const edus = [
        {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/education%2Fimperial.png?alt=media&token=e606822f-da11-491d-b723-b385526290be",
          link: "https://www.imperial.ac.uk/", name: "Imperial College London, UK", years:"2021-2025", degree: "PhD in Design Engineering", awards:"Awarded Fellowships by The Leverhulme Centre for the Future of Technology and The Centre for Human-Inspired Artificial Intelligence at the University of Cambridge and The Alan Turing Institute."},
        {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/education%2Fstandrews.png?alt=media&token=1deb5dea-9cc4-4df8-9d9b-7acfc9ab51f2",
          link: "https://www.st-andrews.ac.uk/", name: "St Andrews University, Scotland, UK", years:"2019-2020", degree: "MSc in Human-Computer Interaction", awards: "Graduated with a Distinction (1st), Earned two medals for Highest Overall Grade in my program and for having the Best Dissertation Project in my program"},
        {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/education%2Fauc.png?alt=media&token=dd174ceb-b055-40b5-bc9d-fdcf46b3e4f4",
          link: "https://www.aucegypt.edu/home", name: "The American University in Cairo, Egypt", years:"2014-2019", degree: "BSc in Computer Engineering, Minor in Digital Media", awards:"Academic Achievement Scholarship, Dean's List, Graduated with Highest Honors, Summa Cum Laude (GPA 3.86/4.0)"},
        {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/education%2Fmes.png?alt=media&token=4c014636-a178-4116-a28e-6b424aeb8b09",
          link: "https://www.mescairo.com/index202012gr.asp", name: "Modern English School Cairo, Egypt", years:"2000-2014", degree:"IGCSE A-Levels", awards:"Highest grade in Egypt for English as a First Language, Second highest grades in Egypt across 7 O-Levels",},
        ];

      if (edus === {} || !edus) {
        return (
            <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
            >
              <Bounce />
            </div>
        );
      } else {
        return Object.keys(edus).map((keyName, i) => (
            <div className={tilesClasses} key={i}>
              <div
                  className="tiles-item reveal-from-bottom"
                  data-reveal-container=".tiles-wrap"
                  data-aos="fade-up"
                  data-aos-delay={parseInt(i * 150)}
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24 illustration-element-06">
                      <a href={edus[keyName].link}>
                        <Image
                            src={edus[keyName].thumbnail}
                            alt="Team member 01"
                            width={100}
                            height={100}
                        />
                      </a>
                    </div>
                    <div className="team-item-content">
                      <h4 className="team-item-name mt-0 mb-4">
                        {edus[keyName].name}
                      </h4>
                      <h5>{edus[keyName].degree}</h5>
                      <h6>{edus[keyName].years}</h6>
                      {edus[keyName].awards}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ));
      }
    }


    if (window.innerWidth < 1024) {
      return (
          <div style={{width: "90%"}}>
            <section {...props} className={outerClasses}>
              <div className="container">
                <div style={{paddingLeft: "10%"}}>
                  <div className="title-box-2">
                    <h5 className="title-left">Professional Experience</h5>
                  </div>
                </div>
                <div className={innerClasses}>

                  <Timeline>

                    <TimelineItem aosType="fade-left" title="July 2024 - Present">
                      United Nations (UN) Women UK
                      <h6>Creative Strategist and Design Coordinator (Volunteer)</h6>
                    </TimelineItem>

                    <TimelineItem aosType="fade-right" title="June 2024 - Present">
                      Bold Insight
                      <h6>UX Researcher (Contract)</h6>
                    </TimelineItem>

                    <TimelineItem aosType="fade-left" title="April 2024 - June 2024">
                      GlobeFlix
                      <h6>Researcher & Developer</h6>
                    </TimelineItem>

                    <TimelineItem aosType="fade-right" title="January 2024 - June 2024">
                      AIxDesign Lab
                      <h6>AI & Design Research Lead</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-left" title="October 2023 - July 2024">
                      The Alan Turing Institute
                      <h6>Enrichment Scheme Recipient & Research Assistant</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-right" title="January 2022 - Present">
                      Imperial College London
                      <h6>Graduate Teaching Assistant for Human-Centred Design Engineering
                        and Responsible Design and Innovation Modules</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-left" title="September 2020 - September 2021">
                      The University of St Andrews
                      <h6>HCI Graduate Research Assistant</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-right" title="April 2020 - June 2021">
                      Stanford University
                      <h6>Online Section Leader & Teacher Mentor</h6>
                      <a className="h6" href="https://drive.google.com/file/d/12IPJe429ZWe8A7Yl8Hk3ptiCYq7gLT6M/view?usp=sharing/">Certificate of Participation</a>
                      <br/>
                      <a className="h6" href="https://codeinplace.stanford.edu/">Code in Place Program</a>
                      <br/>
                      <a className="h6" href="https://mazqtb.axshare.com/#sc=1">My Resources</a>
                    </TimelineItem>
                    <TimelineItem aosType="fade-left" title="February 2021 - May  2021">
                      The American University in Cairo
                      <h6>Advanced Digital Design Graduate Teaching Assistant</h6>
                      <h6>Python Bootcamp Instructor</h6>
                      <a className="h6" href="https://u63f48.axshare.com/#sc=1">My Resources</a>
                    </TimelineItem>
                    <TimelineItem aosType="fade-right" title="August 2017 - August 2019">
                      Freelance
                      <h6>Full-Stack iOS and Android Mobile Application Developer</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-left" title="September 2015 - August 2018">
                      The American University in Cairo
                      <h6>Undergraduate Teaching Assistant</h6>
                      <h6> Assisted in teaching Programming Fundamentals, Digital Design, Data Structures & Algorithms,
                        Operating Systems, Mobile Applications, and Engineering Economy</h6>
                    </TimelineItem>
                  </Timeline>
                </div>
              </div>
            </section>
          </div>
      );
    } else {
      return (
          <div style={{width: "90%"}}>
            <section {...props} className={outerClasses}>
              <div className="container">
                <div className="team section center-content" style={{paddingLeft: "10%"}}>
                  <div className="title-box-2">
                    <h5 className="title-left">Education</h5>
                    <div className="row" height="100px"></div>
                  </div>
                  <div className={innerClasses}>
                    {
                    }
                    <div className={tilesClasses}>
                      <Education></Education>
                    </div>
                  </div>
                  <div className="title-box-2">
                    <h5 className="title-left-skills">Professional Experience</h5>
                  </div>
                </div>
                <div className={innerClasses}>

                  <Timeline>

                    <TimelineItem aosType="fade-left" title="July 2024 - Present">
                      United Nations (UN) Women UK
                      <h6>Creative Strategist and Design Coordinator (Volunteer)</h6>
                    </TimelineItem>

                    <TimelineItem aosType="fade-right" title="June 2024 - Present">
                      Bold Insight
                      <h6>UX Researcher (Contract)</h6>
                    </TimelineItem>

                    <TimelineItem aosType="fade-left" title="April 2024 - June 2024">
                      GlobeFlix
                      <h6>Researcher & Developer</h6>
                    </TimelineItem>

                    <TimelineItem aosType="fade-left" title="January 2024 - June 2024">
                      AIxDesign Lab
                      <h6>AI & Design Research Lead</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-right" title="October 2023 - July 2024">
                      The Alan Turing Institute
                      <h6>Enrichment Scheme Recipient & Research Assistant</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-left" title="January 2022 - Present">
                      Imperial College London
                      <h6>Graduate Teaching Assistant for Human-Centred Design Engineering
                        and Responsible Design and Innovation Modules</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-right" title="September 2020 - September 2021">
                      The University of St Andrews
                      <h6>HCI Graduate Research Assistant</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-left" title="April 2020 - June 2021">
                      Stanford University
                      <h6>Online Section Leader &  Teacher Mentor</h6>
                      <a className="h6" href="https://drive.google.com/file/d/12IPJe429ZWe8A7Yl8Hk3ptiCYq7gLT6M/view?usp=sharing/">Certificate of Participation</a>
                      <br/>
                      <a className="h6" href="https://codeinplace.stanford.edu/">Code in Place Program</a>
                      <br/>
                      <a className="h6" href="https://mazqtb.axshare.com/#sc=1">My Resources</a>
                    </TimelineItem>
                    <TimelineItem aosType="fade-right" title="February 2021 - May 2021">
                      The American University in Cairo
                      <h6>Python Bootcamp Instructor</h6>
                      <a className="h6" href="https://ec62h9.axshare.com/#sc=1">My Resources</a>
                    </TimelineItem>
                    <TimelineItem aosType="fade-left" title="February 2021 - May  2021">
                      The American University in Cairo
                      <h6>Advanced Digital Design Graduate Teaching Assistant</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-right" title="August 2017 - August 2019">
                      Freelance
                      <h6>Full-Stack iOS and Android Mobile Application Developer</h6>
                    </TimelineItem>
                    <TimelineItem aosType="fade-left" title="September 2015 - August 2018">
                      The American University in Cairo
                      <h6>Undergraduate Teaching Assistant</h6>
                      <h6> Assisted in teaching Programming Fundamentals, Digital Design, Data Structures & Algorithms,
                        Operating Systems, Mobile Applications, and Engineering Economy</h6>
                    </TimelineItem>
                  </Timeline>
                </div>

              </div>
            </section>
          </div>
      );
    }
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;
